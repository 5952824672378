<template>
  <lab-task>
    <template #heading>
      <h2>{{ $t('simulations.reloadForEquipmentWarning') }}</h2>
    </template>
    <lab-sim
      :script-src="scriptSrc"
      lab-name="titration-alpha-exercise-part4"
      v-bind="$attrs"
      v-on="$listeners"
      @allGuidanceComplete="submitResponse"
    />
  </lab-task>
</template>

<script>
import {CompletableSimMixin} from '../../mixins/completable-sim';
import {defineComponent} from '@vue/composition-api';
import LabSim from '../simulations/LabSim.vue';
import LabTask from './labs/LabTask';
import i18n from '@/locales/composables/i18n';
import {simulations} from '@/locales/en/simulations';
import {frSimulations} from '@/locales/fr/simulationsFr';

export default defineComponent({
  name: 'OttawaTitrationSIMPart4',
  components: {LabSim, LabTask},
  mixins: [CompletableSimMixin()],
  setup() {
    i18n.mergeLocaleMessage('en', simulations);
    i18n.mergeLocaleMessage('fr', frSimulations);
    return {};
  },
  data: () => ({
    scriptSrc: '/simulations/stable/ottawa-titration/stemble-lab.min.js',
  }),
});
</script>
